import { css } from '@emotion/react' 
import { Component, memo } from 'react';
import loadable from "@loadable/component"
import { 
    Container,
    Typography,
    Grid,
} from '@material-ui/core';
import namesCompany from '../../helpers/NameCompany'
const SimuladorCard = loadable(() => import('../pages/SimuladorCard'))
const CarouselCard = loadable(() => import('../carousel/CarouselCard'))

const simuladorCss = css`
    margin-top: 10px;
`
class Simulador extends Component {
    render() {
        return (
            <Container>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                    <div>
                        <Typography color="primary" variant="h6" align="center">
                            {namesCompany(this.props.pageContext.company).slogan}
                        </Typography>
                    <div css={simuladorCss}>
                        <SimuladorCard pageContext={this.props.pageContext} />
                    </div>
                    <br/>
                    <Typography variant="body2">
                        <sup>*</sup>Esto es un simulador, las condiciones y montos otorgados pueden variar conforme a la evaluación de tu perfil.
                    </Typography>
                </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CarouselCard company={this.props.pageContext.company} />
                    </Grid>
                </Grid>

            </Container>
        );
    }
}

export default memo(Simulador);